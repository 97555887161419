import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useBulkOrder } from '@copper/client/components/BulkOrderTools';
import { useDestination } from '@copper/client/components/TransferFunds/useDestination';
import { EmptyFallback, InfoAccount, InfoList, InfoRow } from '@copper/components';
import { TravelRuleAlert } from '@copper/components/AddressBook/Crypto/TravelRuleAlert';
import { CounterpartyNetworkCard } from '@copper/components/Counterparty/CounterpartyNetworkCard';
import { TransferAmount } from '@copper/components/TransferAmount';
import { FEE_LEVEL_MAP } from '@copper/constants/orders';
import { selectPortfolioEntities } from '@copper/entities/portfolios/portfolios-selector';
import { Alert, Button } from '@copper/ui-kit';
import { PopupContent } from '@copper/ui-kit/Popup/PopupContent';
import s from './Preview.module.css';
const TransferDryOrderPreview = () => {
    const { modalOrderData, setModalContext, onDelete } = useBulkOrder();
    const portfolioEntities = useSelector(selectPortfolioEntities);
    const destination = useDestination(modalOrderData);
    const { orderId, portfolioId } = modalOrderData;
    const portfolio = useMemo(() => portfolioEntities[modalOrderData?.portfolioId ?? ''], [portfolioId]);
    return (_jsxs(PopupContent, { children: [_jsx(TransferAmount, { order: modalOrderData }), modalOrderData?.statusDescription && (_jsx("div", { className: s.alertContainer, children: _jsx(Alert, { type: "error", message: modalOrderData?.status === 'error'
                        ? modalOrderData.statusDescription
                        : 'Please edit this transfer and provide all required information or delete it.' }) })), destination && 'travelRuleInfo' in destination && (_jsx("div", { className: s.alertContainer, children: _jsx(TravelRuleAlert, { address: destination }) })), _jsxs("div", { className: s.controls, children: [_jsx(Button, { text: "Edit", view: "secondary", onClick: () => setModalContext({ orderId: orderId ?? '', mode: 'edit' }) }), _jsx(Button, { text: "Delete", view: "secondary", onClick: () => onDelete([orderId]) })] }), _jsx("div", { className: s.infoSection, children: _jsxs(InfoList, { children: [_jsx(InfoRow, { title: "From", children: portfolio ? _jsx(InfoAccount, { account: portfolio }) : _jsx(EmptyFallback, {}) }), _jsx(InfoRow, { title: 'counterpartyId' in (destination || {}) ? 'Counterparty' : 'To', contentUnderTitle: 'counterpartyId' in (destination || {}), children: destination ? (_jsxs(_Fragment, { children: ['counterpartyId' in destination && (_jsx(CounterpartyNetworkCard, { form: "preview", counterparty: destination })), ('portfolioId' in destination || 'cryptoAddressId' in destination) && (_jsx(InfoAccount, { account: destination })), 'address' in destination && !('cryptoAddressId' in destination) && (_jsx(EmptyFallback, { children: destination.address }))] })) : (_jsx(EmptyFallback, {})) })] }) }), _jsx("div", { className: s.infoSection, children: _jsxs(InfoList, { title: "Details", children: [_jsx(InfoRow, { title: "Fee level", children: _jsx(EmptyFallback, { children: modalOrderData?.feeLevel && FEE_LEVEL_MAP[modalOrderData.feeLevel] }) }), _jsx(InfoRow, { title: "Fee included", children: _jsx(EmptyFallback, { children: modalOrderData?.includeFeeInWithdraw ? 'Yes' : 'No' }) })] }) })] }));
};
export { TransferDryOrderPreview };
