export const LOAN_FORM_TITLES = {
    initial: 'Lend',
    bilateral: 'Bilateral lending',
    agency: 'Agency lending',
    lender: 'Agency lending'
};
export const MATURITY_DATE_DEFINITION = 'The loaned funds may not be recalled for the duration of this term.';
export const LOAN_TYPES_MAP = {
    'agency-lending-collateralised-child-loan': {
        value: 'agency-lending-collateralised-child-loan',
        label: 'Collateralised',
        lendingType: 'Agency lending',
        isAgencyChildLoan: true,
        isBilateral: false,
        tooltipInfo: 'A loan secured by the transfer of collateral to the Copper Agency Lending Desk.',
        hasCollateral: true
    },
    'agency-lending-first-lien-child-loan': {
        value: 'agency-lending-first-lien-child-loan',
        label: 'ClearLoop',
        isAgencyChildLoan: true,
        isBilateral: false,
        lendingType: 'Agency lending',
        tooltipInfo: 'A loan secured against ClearLoop account holdings, with funds exclusively usable within ClearLoop.',
        hasCollateral: false
    },
    'agency-lending-parent-loan': {
        value: 'agency-lending-parent-loan',
        label: '',
        lendingType: 'Agency lending',
        isAgencyChildLoan: false,
        isBilateral: false,
        hasCollateral: false
    },
    'bilateral-lending-title-transfer-collateralised-loan': {
        value: 'bilateral-lending-title-transfer-collateralised-loan',
        lendingType: 'Bilateral lending',
        label: 'Title transfer',
        isAgencyChildLoan: false,
        isBilateral: true,
        tooltipInfo: 'A loan secured by collateral transferred to your dedicated collateral vault.',
        hasCollateral: true
    },
    'bilateral-lending-uncollateralised-loan': {
        value: 'bilateral-lending-uncollateralised-loan',
        lendingType: 'Bilateral lending',
        label: 'Uncollateralised',
        isAgencyChildLoan: false,
        isBilateral: true,
        tooltipInfo: 'A loan with no collateral required.',
        hasCollateral: false
    },
    'agency-lending-uncollateralised-child-loan': {
        value: 'agency-lending-uncollateralised-child-loan',
        lendingType: 'Agency lending',
        label: 'Uncollateralised',
        isAgencyChildLoan: true,
        isBilateral: false,
        tooltipInfo: 'A loan with no collateral required.',
        hasCollateral: false
    },
    'bilateral-lending-pledge-collateralised-loan': {
        value: 'bilateral-lending-pledge-collateralised-loan',
        lendingType: 'Bilateral lending',
        label: 'Pledged',
        isAgencyChildLoan: false,
        isBilateral: false,
        tooltipInfo: "A loan secured by collateral locked in the borrower's dedicated collateral vault.",
        hasCollateral: true
    }
};
