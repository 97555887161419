import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { PortfolioIcon } from '@copper/components';
import { getAvailableBalance } from '@copper/components/Forms/Amount/helpers';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
import s from './ParentPortfolio.module.css';
export const ParentPortfolio = ({ label, portfolio, currency }) => {
    const portfolios = useSelector(selectPortfolios);
    const { entities: currenciesEntities } = useSelector(selectCurrencies);
    const walletsByPortfolioId = useSelector(selectWalletsByPortfolioId);
    const parentPortfolio = portfolios[portfolio?.extra?.parentPortfolioId ?? ''];
    const availableBalance = getAvailableBalance({
        walletsByPortfolioId,
        currenciesEntities,
        portfolio: parentPortfolio,
        currencyId: currency?.currency
    });
    return (_jsxs("div", { className: s.parentWrapper, children: [_jsx("p", { className: s.label, children: label }), _jsxs("div", { className: s.parentPortfolioInfo, children: [parentPortfolio && (_jsx("div", { className: s.parentPortfolioIcon, children: _jsx(PortfolioIcon, { portfolio: parentPortfolio || '' }) })), _jsxs("div", { className: s.nameWrapper, children: [_jsx("div", { className: s.parentPortfolioName, children: parentPortfolio?.isShadow ? 'Restricted account' : parentPortfolio?.portfolioName }), !parentPortfolio?.isShadow && parentPortfolio?.balance && (_jsx("div", { className: s.balance, children: availableBalance }))] })] })] }));
};
