import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { FormControl, FormLabel, NumberInput, Slider } from '@copper/ui-kit';
import { trimTrailingZeroes } from '@copper/utils';
import { getAvailableBalance, getSliderPercentagePosition } from './helpers';
import s from './Amount.module.css';
const defaultScale = { 0: '0%', 25: '25%', 50: '50%', 75: '75%', 100: '100%' };
const Amount = ({ control, errorMessage, name, leftSection, rightSection, currency, mainCurrency, portfolioId, hideSlider = false, view = 'primary', testId, formLabel = 'Amount', labelRightSection, disabled = false, forceAvailable, sliderScale = defaultScale, sliderStep = 25, useScaleValue = false }) => {
    const { getDecimals } = useCurrenciesInfo();
    const { entities: currenciesEntities } = useSelector(selectCurrencies);
    const walletsByPortfolioId = useSelector(selectWalletsByPortfolioId);
    const portfolios = useSelector(selectPortfolios);
    const [sliderValue, setSliderValue] = useState(0);
    const walletAvailableBalance = useMemo(() => {
        const portfolio = portfolios[portfolioId ?? ''];
        return getAvailableBalance({
            walletsByPortfolioId,
            currenciesEntities,
            portfolio,
            currencyId: currency,
            mainCurrencyId: mainCurrency
        });
    }, [portfolios, portfolioId, walletsByPortfolioId, currenciesEntities, currency, mainCurrency]);
    const availableBalance = forceAvailable ?? walletAvailableBalance;
    const shortDecimal = getDecimals(currency);
    const maxDecimal = getDecimals(currency, {
        mainCurrencyId: mainCurrency,
        useDictionaryDecimal: true
    });
    const { field: { onChange, value } } = useController({
        name,
        control: control
    });
    useEffect(() => {
        if (useScaleValue) {
            setSliderValue(Math.min(value, rangeMaxValue));
            return;
        }
        if (hideSlider) {
            return;
        }
        if (value) {
            const position = getSliderPercentagePosition(value, availableBalance);
            setSliderValue(position);
        }
        else {
            onChange('');
            setSliderValue(0);
        }
    }, [availableBalance, hideSlider, value]);
    useEffect(() => {
        if (availableBalance) {
            onChange(trimTrailingZeroes(new BigNumber(value ?? 0).toFixed(maxDecimal, 1)));
        }
    }, [maxDecimal]);
    const handleSliderChange = useCallback((percent) => {
        if (hideSlider) {
            return;
        }
        setSliderValue(percent);
        if (useScaleValue) {
            return onChange(percent);
        }
        if (availableBalance) {
            if (percent === 100) {
                onChange(trimTrailingZeroes(new BigNumber(availableBalance).toFixed(maxDecimal, 1)));
            }
            else {
                const newValue = new BigNumber(availableBalance)
                    .times(percent)
                    .div(100)
                    .toFixed(shortDecimal);
                onChange(trimTrailingZeroes(newValue));
            }
        }
    }, [hideSlider, availableBalance, onChange, maxDecimal, shortDecimal]);
    const handleValueChange = useCallback(({ value }, { source }) => {
        onChange(value);
        if (hideSlider || useScaleValue) {
            return;
        }
        if (source === 'event' && availableBalance) {
            const position = getSliderPercentagePosition(value, availableBalance);
            setSliderValue(position);
        }
    }, [hideSlider, onChange, availableBalance]);
    const rangeMaxValue = useMemo(() => Number(Object.keys(sliderScale).reverse()[0] ?? 0), [sliderScale]);
    return (_jsxs("div", { className: s.amount, children: [_jsxs(FormControl, { errorMessage: errorMessage, children: [_jsx(FormLabel, { view: view, rightSection: labelRightSection, children: formLabel }), _jsx(NumberInput, { size: "l", view: view, value: value, decimalScale: maxDecimal, onValueChange: handleValueChange, leftSection: leftSection, rightSection: rightSection, testId: testId, disabled: disabled })] }), !hideSlider && !disabled && (_jsx("div", { className: s.slider, children: _jsx(Slider, { step: sliderStep, view: view, max: rangeMaxValue, value: sliderValue, onChange: handleSliderChange, marks: sliderScale }) }))] }));
};
export { Amount };
