import { useDispatch } from 'react-redux';
import { fetchAnnouncements } from '@copper/entities/announcements/announcements-reducer';
import { fetchDictionaries, fetchFeeTypes, fetchNetworks } from '@copper/entities/dictionaries/dictionaries-reducer';
import { fetchJurisdictions } from '@copper/entities/jurisdictions/jurisdictions-reducer';
import { fetchLegalEntities } from '@copper/entities/legalEntities/legalEntities-reducer';
import { fetchOrganizations } from '@copper/entities/organizations/organizations-reducer';
import { fetchAuthorizedActions } from '@copper/entities/permissions/permissions-reducer';
import { fetchUISettings } from '@copper/entities/revault/revault-reducer';
import { fetchUser } from '@copper/entities/user/user-reducer';
import { getCurrentOrganizationId } from '@copper/helpers/organizations';
import { useFetchOrganization } from '@copper/hooks/useFetchOrganization';
export const useFetchClientData = () => {
    const dispatch = useDispatch();
    const fetchOrganization = useFetchOrganization();
    return async (userId, preventCurrentOrgFetch = false) => {
        try {
            const [organizations] = await Promise.all([
                dispatch(fetchOrganizations()).unwrap(),
                dispatch(fetchDictionaries()).unwrap(),
                dispatch(fetchNetworks()).unwrap(),
                dispatch(fetchFeeTypes()).unwrap(),
                dispatch(fetchJurisdictions()).unwrap(),
                dispatch(fetchLegalEntities()).unwrap(),
                dispatch(fetchUISettings()) // always success resolve
            ]);
            const currentOrganizationId = getCurrentOrganizationId(organizations, userId);
            await dispatch(fetchAuthorizedActions({ organizationId: currentOrganizationId })).unwrap();
            await dispatch(fetchAnnouncements({ organizationId: currentOrganizationId })).unwrap();
            if (!preventCurrentOrgFetch) {
                if (currentOrganizationId) {
                    await fetchOrganization(getCurrentOrganizationId(organizations, userId));
                }
            }
            await dispatch(fetchUser()).unwrap();
        }
        catch (error) {
            return Promise.reject(error);
        }
    };
};
