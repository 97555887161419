import { useWebsocketContext } from '@copper/helpers/websocket-context';
import { useEffect, useRef } from 'react';
export const useSocketsSubscribe = ({ uri, params, active = true, onUpdate, onError, resolveDelay, handleLoader = () => { } }) => {
    const { createSubscription, removeSubscription } = useWebsocketContext();
    const currentSubscriptionId = useRef('');
    const currentSubscriptionPromiseRef = useRef(new Promise(() => { }));
    const unsubscribe = () => {
        if (currentSubscriptionId.current) {
            removeSubscription(currentSubscriptionId.current);
            currentSubscriptionId.current = '';
        }
    };
    const subscribe = () => {
        handleLoader(true);
        const { subscriptionId, subscriptionPromise } = createSubscription({
            uri,
            params,
            onUpdate,
            onError,
            resolveDelay
        });
        currentSubscriptionPromiseRef.current = subscriptionPromise;
        subscriptionPromise.then(() => {
            handleLoader(false);
        });
        currentSubscriptionId.current = subscriptionId;
    };
    useEffect(() => {
        if (!active) {
            unsubscribe();
            return;
        }
        unsubscribe();
        subscribe();
        // return unsubscribe for unmount case
        return unsubscribe;
    }, [uri, JSON.stringify(params), active]);
    const forceResubscribe = () => {
        unsubscribe();
        subscribe();
    };
    return {
        forceResubscribe,
        subscriptionId: currentSubscriptionId?.current,
        subscriptionPromise: currentSubscriptionPromiseRef.current
    };
};
