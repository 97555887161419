import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { MobileAppBanner } from '@copper/components/MobileAppPromo';
import { getDynamicMobileBannerStyle } from '@copper/components/MobileAppPromo/helpers';
import { useGetBoundingClientRect } from '@copper/hooks';
import { Button, IconSigning } from '@copper/ui-kit';
import { CancelOrder } from '../CancelOrder';
import s from './StartSettlement.module.css';
const StartSettlementButton = ({ onSign, type = 'complex', order, loading }) => {
    const [rect, ref] = useGetBoundingClientRect('scroll');
    const [isBusy, setIsBusy] = useState(false);
    const handleSignButtonClick = async () => {
        setIsBusy(true);
        await onSign();
        setIsBusy(false);
    };
    if (type === 'simple') {
        return _jsx(Button, { loading: isBusy || loading, text: "Sign", onClick: handleSignButtonClick });
    }
    const orderName = order.orderType === 'wallet-message' ? 'message' : 'transaction';
    return (_jsxs("div", { className: s.container, ref: ref, children: [_jsx("div", { className: s.iconSigningWrapper, children: _jsx(IconSigning, { className: s.iconSigning }) }), _jsxs("div", { className: s.title, children: ["Sign ", orderName] }), _jsx("div", { className: s.description, children: order.orderType === 'wallet-message'
                    ? 'Review the below message carefully before signing.'
                    : 'You need to sign one transaction at a time. Once this transaction has been broadcast, you can move to the next one.' }), _jsx(Button, { loading: isBusy || loading, text: "Sign", fullWidth: true, size: "l", onClick: handleSignButtonClick }), _jsx(CancelOrder, { order: order, showSuccessMessage: true }), _jsx("div", { className: s.bannerWrapper, style: getDynamicMobileBannerStyle(rect?.top, ref.current?.offsetTop), children: _jsx(MobileAppBanner, {}) })] }));
};
export { StartSettlementButton };
