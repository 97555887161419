import { jsx as _jsx } from "react/jsx-runtime";
import { selectAuth, selectIsAuthorized } from '@copper/entities/auth/auth-selector';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData, serializeParams } from '@copper/utils';
import cuid from 'cuid';
import { delay } from 'lodash-es';
import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getApiUrl } from './api-host';
import { getWsApiHost } from './api-host-ws';
const SERVER_REQUEST_TIMEOUT = 25000;
const HEARTBEAT_INTERVAL = 15000;
const RECONNECT_TIMER_STEP = 1000;
const MAX_RECONNECT_TIMEOUT = 10000;
export const WebsocketContext = React.createContext({});
export const WebsocketContextProvider = ({ children }) => {
    const isAuthorized = useSelector(selectIsAuthorized);
    const { openSnackbar } = useSnackBar();
    const { token } = useSelector(selectAuth);
    const connectRef = useRef(null);
    const heartbeatTimerIdRef = useRef();
    const reconnectTimerRef = useRef(0);
    const subscriptions = useRef({});
    const messagesQueue = useRef({});
    // used for send sendAuthorizedMessage func,
    // otherwise if using value directly from select there is a bug when message sends with prev token.
    // dependencies + useCallback/useEffect not solving it
    const tokenRef = useRef(token);
    const getConnectState = () => {
        if (!connectRef.current) {
            return 'closed';
        }
        switch (connectRef.current.readyState) {
            case 0:
                return 'connecting';
            case 1:
                return 'open';
            case 2:
                return 'closing';
            case 3:
            default:
                return 'closed';
        }
    };
    const scheduleHeartbeat = () => {
        if (getConnectState() !== 'open')
            return;
        sendMessage({ method: 'PING' });
        clearTimeout(heartbeatTimerIdRef.current);
        heartbeatTimerIdRef.current = setTimeout(scheduleHeartbeat, HEARTBEAT_INTERVAL);
    };
    const disconnect = (code, reason) => {
        clearTimeout(heartbeatTimerIdRef.current);
        clearSubscriptionsTimeouts();
        if (getConnectState() === 'open') {
            connectRef.current?.close(code, reason);
        }
        connectRef.current = null;
    };
    const sendMessage = (message) => {
        const messageToSent = { ...message, uri: `/platform${message?.uri ?? ''}` };
        if (getConnectState() === 'open') {
            connectRef.current?.send(JSON.stringify(messageToSent));
        }
        else {
            messagesQueue.current[message?.headers?.['Correlation-Id'] ?? cuid()] = message;
        }
    };
    const sendAuthorizedMessage = (message) => {
        sendMessage({
            ...message,
            headers: { ...message.headers, Authorization: `Bearer ${tokenRef.current}` }
        });
    };
    const restoreSubsriptionsAndResolveQueue = () => {
        // copy current state than reset queue and than iterate over copy to avoid duplication;
        const queue = { ...messagesQueue.current };
        messagesQueue.current = {};
        Object.entries(subscriptions?.current).forEach(([subscriptionId, subscription]) => {
            queue[subscriptionId] = {
                method: 'SUBSCRIBE',
                uri: subscription.uri,
                headers: { 'Correlation-Id': subscriptionId }
            };
        });
        Object.values(queue).forEach((message) => sendAuthorizedMessage(message));
    };
    const initConnect = () => {
        if (connectRef.current) {
            return;
        }
        connectRef.current = new WebSocket(getWsApiHost(getApiUrl()));
        connectRef.current.onopen = () => {
            restoreSubsriptionsAndResolveQueue();
            scheduleHeartbeat();
        };
        connectRef.current.onclose = (event) => {
            // Log the connection break event for debugging to see the reason - can be deleted later on
            console.info(event, 'ws connection close event');
            // logout
            if (event.code === 4000) {
                subscriptions.current = {};
                messagesQueue.current = {};
                return;
            }
            // token refresh
            if (event.code === 4001) {
                return;
            }
            // handle connection close by server or network and try to restore
            connectRef.current = null;
            clearSubscriptionsTimeouts();
            reconnectTimerRef.current = Math.min(reconnectTimerRef.current + RECONNECT_TIMER_STEP, MAX_RECONNECT_TIMEOUT);
            setTimeout(initConnect, reconnectTimerRef.current);
        };
        connectRef.current.onmessage = (messageEvent) => {
            const parsedData = JSON.parse(messageEvent.data);
            const correlationId = parsedData?.headers?.['Correlation-Id'];
            if (!correlationId) {
                return;
            }
            const subscription = subscriptions.current[correlationId];
            if (subscription) {
                clearTimeout(subscription.timeoutTimerId);
                subscription.resolveDelay
                    ? delay(() => {
                        subscription.resolve(parsedData);
                    }, subscription.resolveDelay)
                    : subscription.resolve(parsedData);
                if (parsedData?.body?.error) {
                    subscription.errorCallback({
                        error: parsedData?.body?.error,
                        message: parsedData?.body?.message,
                        'Correlation-Id': correlationId
                    });
                    return removeSubscription(correlationId);
                }
                subscription.callback(parsedData.body, subscription.isFirstDataBatch);
                subscriptions.current[correlationId].isFirstDataBatch = false;
            }
        };
    };
    useEffect(() => {
        if (!isAuthorized) {
            return disconnect(4000, 'logout');
        }
        initConnect();
        return () => {
            disconnect(4000, 'logout');
        };
    }, [isAuthorized]);
    useEffect(() => {
        // Recconect when token exist and changed, login/loguout handle in other useEffect
        if (tokenRef.current && token && tokenRef.current !== token) {
            disconnect(4001, 'token refresh');
            initConnect();
        }
        tokenRef.current = token;
    }, [token]);
    const createSubscription = ({ uri, params, onUpdate, onError = (error) => openSnackbar(getErrorData(error)), resolveDelay }) => {
        const subscriptionId = cuid();
        const stringifiedParams = serializeParams(params);
        const uriWithParams = `${uri}${stringifiedParams}`;
        const timeoutTimerId = setTimeout(() => {
            onError({
                message: `request timeout, response time exceed ${SERVER_REQUEST_TIMEOUT / 1000}s`,
                'Correlation-Id': subscriptionId
            });
            removeSubscription(subscriptionId);
        }, SERVER_REQUEST_TIMEOUT);
        sendAuthorizedMessage({
            method: 'SUBSCRIBE',
            uri: uriWithParams,
            headers: { 'Correlation-Id': subscriptionId }
        });
        let resolve = () => { };
        const subscriptionPromise = new Promise((_resolve) => {
            resolve = _resolve;
        });
        subscriptions.current[subscriptionId] = {
            uri: uriWithParams,
            callback: onUpdate,
            errorCallback: onError,
            resolve,
            resolveDelay,
            isFirstDataBatch: true,
            timeoutTimerId
        };
        return { subscriptionId, subscriptionPromise };
    };
    const removeSubscription = (subscriptionId) => {
        const subscription = subscriptions?.current[subscriptionId];
        if (!subscription) {
            console.warn('subscription not exist', subscriptionId);
            return;
        }
        sendAuthorizedMessage({
            method: 'UNSUBSCRIBE',
            uri: subscription.uri,
            headers: { 'Correlation-Id': subscriptionId }
        });
        clearTimeout(subscription.timeoutTimerId);
        delete messagesQueue?.current[subscriptionId];
        delete subscriptions?.current[subscriptionId];
    };
    const clearSubscriptionsTimeouts = () => Object.values(subscriptions.current).forEach((subscription) => clearTimeout(subscription.timeoutTimerId));
    return (_jsx(WebsocketContext.Provider, { value: { createSubscription, removeSubscription }, children: children }));
};
export const useWebsocketContext = () => useContext(WebsocketContext);
