import * as portfolios from '@copper/api/portfolios';
import { normalizePortfolios } from './portfolios-normalize';
export const fetchWithdrawTargets = (params) => portfolios.getWithdrawTargets(params).then((response) => response.data.withdrawTargets);
export const updatePortfolioBalances = (portfolioId) => portfolios.updatePortfolioBalances(portfolioId);
export const reconcileAutoDiscovery = (portfolioId) => portfolios.reconcileAutoDiscovery(portfolioId);
export const updatePortfolioWhiteLock = (portfolioId, whiteLock) => portfolios.updatePortfolioWhiteLock(portfolioId, whiteLock);
export const unbindEligibility = (portfolioId) => portfolios.unbindEligibility(portfolioId).then((response) => response.data);
export const fetchShards = (params) => portfolios.getShards(params).then((response) => response.data.shards);
export const createShard = (params) => portfolios.createShard(params);
export const convertShardToWarm = (data, portfolioId, shardId) => portfolios.convertShardToWarm(data, portfolioId, shardId);
export const fetchPortfolioByReference = (referenceNumber) => portfolios
    .getPortfolios({ referenceNumber })
    .then((response) => normalizePortfolios(response.data?.portfolios ?? []));
export const fetchPortfoliosList = (organizationId) => portfolios
    .getPortfolios({ organizationId })
    .then((response) => normalizePortfolios(response.data?.portfolios));
export const getShardCreationSessions = (portfolioId) => portfolios.getShardCreationSessions(portfolioId).then((response) => response.data.shardCreations);
export const createShardCreationSession = (portfolioId, data) => portfolios.createShardCreationSession(portfolioId, data).then((response) => response.data);
export const cancelShardCreationSession = (portfolioId, sessionId) => portfolios.cancelShardCreationSession(portfolioId, sessionId).then((response) => response.data);
export const createBoundedToken = (portfolioId) => portfolios.createBoundedToken(portfolioId).then((response) => ({
    token: response.data.token,
    correlationId: response.config.headers['Correlation-Id']
}));
