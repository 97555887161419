import { request } from '@copper/utils';
const normalizeAdjustmentsParams = ({ currencies, ...params }) => ({
    ...params,
    currencies: currencies?.join(',')
});
const normalizeRequestsParams = ({ currencies, statuses, ...params }) => ({
    ...params,
    currency: currencies?.join(','),
    status: statuses?.join(',')
});
const normalizeTransactionsParams = ({ currencies, ...params }) => ({
    ...params,
    currencies: currencies?.join(',')
});
export const getTreasuries = () => request.get('/treasury/treasuries?includeTotalBalance=true');
export const getTreasury = ({ treasuryId }) => request.get(`/treasury/treasuries/${treasuryId}`);
export const getTreasuryAdjustments = (params) => request.get('/treasury/adjustments', {
    params: normalizeAdjustmentsParams(params)
});
export const getTreasuryBalances = (params) => request.get('/treasury/aggregated-balances', {
    headers: {
        Accept: 'application/vnd.get-aggregated-balances.v2+json'
    },
    params
});
export const getTreasuryFees = (params) => request.get('/treasury/fees-records', {
    params
});
export const getTreasuryRequests = (params) => request.get('/treasury/requests', {
    params: normalizeRequestsParams(params)
});
export const getTreasuryRequest = ({ treasuryRequestId }) => request.get(`/treasury/requests/${treasuryRequestId}`);
export const getTreasuryTransactions = (params) => request.get('/treasury/transactions', {
    params: normalizeTransactionsParams(params)
});
export const getTreasuryTransaction = (transactionId) => request.get(`/treasury/transactions/${transactionId}`);
export const getTreasuryWallet = (walletId) => request.get(`/treasury/wallets/${walletId}`);
export const getTreasuryWallets = (params) => request.get('/treasury/wallets', { params });
export const createTreasuryRequest = (data) => request.post('/treasury/requests', data);
export const cancelTreasuryRequest = (treasuryRequestId) => request.patch(`/treasury/requests/${treasuryRequestId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.cancel-request+json'
    }
});
export const createTreasuryWallet = (data) => request.post('/treasury/wallets', data);
export const deleteTreasuryWallet = (walletId) => request.delete(`/treasury/wallets/${walletId}`);
export const uploadTreasuryWalletShard = (data) => request.patch(`/treasury/wallets/${data.walletId}`, {
    walletShard: data.walletShard,
    walletShardPassword: data.walletShardPassword
}, {
    headers: {
        'Content-Type': 'application/vnd.upload-treasury-wallet-shard-key+json'
    }
});
export const markAsWorking = (data) => request.patch(`/treasury/requests/${data.treasuryRequestId}`, { fromWalletId: data.fromWalletId }, {
    headers: {
        'Content-Type': 'application/vnd.mark-as-working-request+json'
    }
});
export const markAsPartSigned = ({ treasuryRequestId, ...data }) => request.patch(`/treasury/requests/${treasuryRequestId}`, {
    ...data
}, {
    headers: {
        'Content-Type': 'application/vnd.upload-part-signed-tx+json'
    }
});
export const markAsFullSigned = ({ treasuryRequestId, ...data }) => request.patch(`/treasury/requests/${treasuryRequestId}`, {
    ...data
}, {
    headers: {
        'Content-Type': 'application/vnd.upload-full-signed-tx+json'
    }
});
export const dryRun = (data) => request.post('/dry-run-requests', {
    ...data
});
export const replaceByFee = ({ treasuryRequestId, ...data }) => request.patch(`/treasury/requests/${treasuryRequestId}`, {
    ...data
}, {
    headers: {
        'Content-Type': 'application/vnd.replace-by-fee+json'
    }
});
