import BigNumber from 'bignumber.js';
import { upperFirst } from 'lodash-es';
import { isFeesVault } from '@copper/helpers/portfolios';
export const isClearloopSettlementInTrading = (order) => order.limitType === 'off-exchange';
export const isOxygenSecuritiesTrade = (order) => ['buy', 'sell'].includes(order.orderType) && order.extra?.oxygen;
export const isEotcTrade = (order) => ['eotc', 'rfq', 'stream'].includes(order.limitType ?? '');
export const isMarketTrade = (order) => ['sell', 'buy'].includes(order.orderType) &&
    ['market', 'limit', 'stop-loss', 'stop-limit'].includes(order.limitType ?? '');
export const isSettle = (order) => ['otc'].includes(order.limitType ?? '');
export const isSettleCreatedByAdmin = (order) => ['buy', 'sell'].includes(order.orderType) &&
    ['otc'].includes(order.limitType ?? '') &&
    order.extra?.createdByAdmin;
export const isLoanOrderType = (order) => [
    'secure-collateral',
    'release-collateral',
    'rebalance',
    'transfer-collateral',
    'pledge-collateral',
    'secure-balance',
    'release-balance'
].includes(order.orderType);
export const isAdjustmentOrder = (order) => order.extra?.adjustmentOrder === true;
export const isSecondClientSignatureExpected = (order) => Boolean(order.status === 'part-signed-tx-added' && order.extra?.additionalPartSigned?.length);
export const getAmountInReporting = (order, currentRate = '') => {
    const { reportingCurrencyRate = '', terminatedReportingCurrencyRate = '' } = order.extra || {};
    const factor = new BigNumber(terminatedReportingCurrencyRate || reportingCurrencyRate || currentRate || '1');
    return new BigNumber(order.amount).multipliedBy(factor).toFixed();
};
export const isAlgoGovernanceOrder = (order) => {
    const { amount, baseCurrency, extra, mainCurrency } = order;
    const { estimatedFees, memo } = extra ?? {};
    const isAmountZero = new BigNumber(amount).eq(0) || new BigNumber(amount).minus(estimatedFees?.fee ?? 0).eq(0);
    return (isAmountZero &&
        baseCurrency === 'ALGO' &&
        mainCurrency === 'ALGO' &&
        memo?.toLowerCase().includes('gov'));
};
export const isClearloopOrder = (order) => order.extra?.clearLoop && ['withdraw', 'deposit'].includes(order.orderType);
export const isClearloopDelegation = (order) => (order.extra?.clearLoop &&
    ['trading', 'trading-vault'].includes(order.portfolioType) &&
    order.orderType === 'withdraw') ||
    (order.portfolioType === 'clearloop' &&
        order.orderType === 'deposit' &&
        !order.extra?.settlementOrderId);
export const isClearloopUndelegation = (order) => (order.extra?.clearLoop &&
    ['trading', 'trading-vault'].includes(order.portfolioType) &&
    order.orderType === 'deposit') ||
    (order.portfolioType === 'clearloop' &&
        order.orderType === 'withdraw' &&
        !order.extra?.settlementOrderId);
export const isAgencyLendingDeposit = (order) => order.extra?.loanId && order.extra?.loanType && order.orderType === 'deposit';
export const isSelfClearloopDelegation = (order) => order.extra?.selfClearLoop &&
    ['trading', 'trading-vault'].includes(order.portfolioType) &&
    order.orderType === 'deposit';
export const isSelfClearloopUndelegation = (order) => order.extra?.selfClearLoop &&
    ['trading', 'trading-vault'].includes(order.portfolioType) &&
    order.orderType === 'withdraw';
export const isSelfClearloopClaim = (order) => order.extra?.selfClearLoop &&
    ['trading', 'trading-vault'].includes(order.portfolioType) &&
    order.orderType === 'buy';
// CL settlement order contains deposit and withdraw orderType,
// as well Delegation Order in CL portfolioType
export const isClearloopSettlement = (order) => Boolean(order.extra?.clearLoop && order.portfolioType === 'clearloop' && order.extra.settlementOrderId);
export const isLendingOrder = (order) => isLoanOrderType(order);
const stakeTransactionTypes = [
    'stake-delegation',
    'stake-nomination',
    'stake-undelegation',
    'pool-creation',
    'complete-withdrawal',
    'transfer-stake',
    'chill',
    'rebond-stake',
    'stake-complete-deposit',
    'importance-transfer',
    'take-reward',
    'register-online',
    'register-offline'
];
export const isStakeOrder = (order) => {
    const { extra } = order;
    const { blockchainTransactionType, depositOrigin } = extra || {};
    if (depositOrigin === 'claim-reward' && order.orderType === 'deposit')
        return true;
    if (stakeTransactionTypes.includes(blockchainTransactionType || ''))
        return true;
    return ['claim-rebate', 'earn-rebate', 'earn-reward'].includes(order.orderType);
};
export const getStakeTransactionTitle = (order) => {
    const { extra, orderType } = order;
    const { blockchainTransactionType, isRewardIncreaseBalance, depositOrigin } = extra ?? {};
    switch (blockchainTransactionType || orderType) {
        case 'claim-rebate':
            return 'Claim rebate';
        case 'earn-rebate':
            return 'Earn rebate';
        case 'earn-reward':
            if (isRewardIncreaseBalance)
                return 'Earned rewards';
            return 'Accrued rewards';
        case 'deposit':
            if (depositOrigin === 'claim-reward')
                return 'Claimed rewards';
        case 'stake-delegation':
            return 'Stake';
        case 'stake-nomination':
            return 'Stake';
        case 'chill':
            return 'Chill';
        case 'rebond-stake':
            return 'Rebond';
        case 'stake-undelegation':
            return 'Unstake';
        case 'pool-creation':
            return 'Add validator';
        case 'complete-withdrawal':
            return 'Claim';
        case 'transfer-stake':
            return 'Change validator';
        case 'stake-complete-deposit':
            return 'Stake';
        case 'importance-transfer':
            return 'Importance transfer';
        case 'take-reward':
            return 'Get rewards';
        case 'register-online':
            return 'Register online';
        case 'register-offline':
            return 'Register offline';
        default:
            return '';
    }
};
export const getEnhancedOrderType = ({ order }) => {
    let type = upperFirst(order.orderType.split('-').join(' '));
    let subType = 'Transfer';
    let isRepeatable = false;
    if (type === 'Withdraw') {
        type = 'Withdrawal';
        isRepeatable = true;
    }
    if (type === 'Wallet message') {
        type = 'Wallet message';
        subType = 'DeFi';
    }
    if (order.orderType === 'deposit' && order?.extra?.rejected) {
        return {
            type: 'Rejected deposit',
            subType: 'Transfer'
        };
    }
    if (isAdjustmentOrder(order)) {
        return {
            type: order.orderType === 'deposit' ? 'Quote fee' : 'Network fee',
            subType: 'Adjustments'
        };
    }
    if (['buy-mmf-tokens', 'sell-mmf-tokens'].includes(order.orderType)) {
        return {
            type: 'Convert',
            isRepeatable: true
        };
    }
    if (order.orderType === 'oxygen-delegate') {
        return {
            type: ' CS pledge'
        };
    }
    if (order.orderType === 'oxygen-undelegate') {
        return {
            type: ' CS unpledge'
        };
    }
    if (order.extra?.treasurySettlement) {
        subType = 'Treasury settlement';
        isRepeatable = false;
    }
    if (order?.extra?.oxygen?.method) {
        switch (order.extra.oxygen.method) {
            case 'buy':
                return { type: 'Buy', subType };
            case 'sell':
                return { type: 'Sell', subType };
            case 'cash-deposit':
                return { type: 'Cash deposit', subType };
            case 'accrued-interest':
                return { type: 'Accrued interest', subType };
            case 'trade-fees':
                return { type: 'Trade fees', subType };
            case 'vat':
                return { type: 'V.A.T.', subType };
            case 'cash-withdraw':
                return { type: 'Cash withdraw', subType };
            case 'purchase-of-security':
                return { type: 'Purchase of security', subType };
            case 'sale-of-security':
                return { type: 'Sale of security', subType };
            case 'create-pledge':
                return { type: 'Pledge' };
            case 'add-pledge-collateral':
                return { type: 'Add collateral' };
            case 'create-more-dct':
                return { type: 'Create more DCT' };
            case 'pledge-repay':
                return { type: 'Redeem DCT' };
            case 'withdraw-pledge-collateral':
                return { type: 'Remove collateral' };
            case 'credit-adjustment':
                return { type: 'Credit adjustment' };
            case 'debit-adjustment':
                return { type: 'Debit adjustment' };
            case 'coupon-payment':
                return { type: 'Coupon payment' };
            case 'custody-fees':
                return { type: 'Custody fees' };
            case 'commission-adjustment':
                return { type: 'Commission adjustment' };
            case 'transaction-fees':
                return { type: 'Transaction fees' };
            case 'reversal-entry':
                return { type: 'Reversal entry' };
            case 'tax-on-dividend':
                return { type: 'Tax on dividend' };
            case 'cash-dividend':
                return { type: 'Cash dividend' };
            case 'dividend':
                return { type: 'Dividend' };
            case 'subscription-fees':
                return { type: 'Subscription fees' };
            case 'redemption-fees':
                return { type: 'Redemption fees' };
        }
    }
    if (isEotcTrade(order) || isMarketTrade(order) || isOxygenSecuritiesTrade(order)) {
        return {
            type,
            subType: 'Trade',
            isRepeatable: true
        };
    }
    else if (isSettle(order)) {
        return {
            type,
            subType: 'Settle',
            isRepeatable: true
        };
    }
    else if (isSelfClearloopClaim(order)) {
        return {
            type: 'Claim',
            subType: 'ClearLoop',
            isRepeatable: true
        };
    }
    else if (isClearloopSettlement(order) || isClearloopSettlementInTrading(order)) {
        if (['deposit', 'buy'].includes(order.orderType)) {
            return {
                type: 'Funds in',
                subType: 'ClearLoop settlement',
                isRepeatable: isClearloopSettlementInTrading(order)
            };
        }
        if (['withdraw', 'sell'].includes(order.orderType)) {
            return {
                type: 'Funds out',
                subType: 'ClearLoop settlement',
                isRepeatable: isClearloopSettlementInTrading(order)
            };
        }
    }
    else if (order.extra?.selfClearLoop) {
        if (isSelfClearloopDelegation(order)) {
            return {
                type: 'Delegation',
                subType: 'ClearLoop',
                isRepeatable: true
            };
        }
        if (isSelfClearloopUndelegation(order)) {
            return {
                type: 'Undelegation',
                subType: 'ClearLoop',
                isRepeatable: true
            };
        }
    }
    else if (order.extra?.clearLoop) {
        if (isClearloopDelegation(order)) {
            return {
                type: 'Delegation',
                subType: 'ClearLoop'
            };
        }
        if (isClearloopUndelegation(order)) {
            return {
                type: 'Undelegation',
                subType: 'ClearLoop'
            };
        }
    }
    else if (order.extra?.toInvoiceId) {
        subType = 'Invoice payment';
        isRepeatable = false;
    }
    else if (order.extra?.loanId || isLendingOrder(order)) {
        if (order.orderType === 'claim-collateral') {
            type = 'Collateral claimed';
        }
        if (['transfer-collateral', 'secure-collateral'].includes(order.orderType)) {
            type = 'Collateral transfer';
        }
        if (order.orderType === 'loan-disbursement') {
            type = 'Loan disbursal';
        }
        if (order.orderType === 'loan-repayment') {
            type = 'Loan repayment';
        }
        if (['release-collateral', 'return-collateral'].includes(order.orderType)) {
            type = 'Collateral return';
        }
        if (order.orderType === 'deposit') {
            if (order.extra?.initializerOrderType === 'claim-collateral') {
                type = 'Collateral claimed';
            }
            if (order.extra?.initializerOrderType === 'seize-collateral') {
                type = 'Collateral seized';
            }
            if (order.extra?.initializerOrderType === 'loan-disbursement') {
                type = 'Loan proceeds';
            }
            if (order.extra?.initializerOrderType === 'loan-repayment') {
                type = 'Loan repayment';
            }
            if (order.extra?.initializerOrderType &&
                [
                    'transfer-collateral',
                    'return-collateral',
                    'secure-collateral',
                    'release-collateral'
                ].includes(order.extra?.initializerOrderType)) {
                type = 'Collateral receive';
            }
        }
        subType = 'Loan';
        isRepeatable = false;
    }
    else if (isStakeOrder(order)) {
        type = getStakeTransactionTitle(order);
        subType = 'Staking';
        isRepeatable = true;
    }
    if (order?.extra?.blockchainTransactionType === 'activate') {
        type = 'Wallet activation';
        subType = '';
        isRepeatable = false;
    }
    if (isFeesVault(order.portfolioType) && order.extra?.feesRequestId) {
        if (order.extra.feesVaultOrderType === 'proxy-activation') {
            type = 'Proxy activation';
        }
        else if (order.extra?.feesVaultOrderType === 'proxy-deposit-fee') {
            type = 'Proxy deposit fee';
        }
        else {
            type = 'Fees order';
        }
        isRepeatable = false;
    }
    return {
        type,
        subType,
        isRepeatable
    };
};
export const getFirstCurrency = (order) => {
    if (!isEotcTrade(order) && !isSettle(order)) {
        return order.baseCurrency;
    }
    return order.orderType === 'buy' ? order.quoteCurrency : order.baseCurrency;
};
export const getSecondCurrency = (order) => {
    if (!isEotcTrade(order) && !isSettle(order)) {
        return;
    }
    return order.orderType === 'buy' ? order.baseCurrency : order.quoteCurrency;
};
export const getQuoteAmount = (order) => {
    const { extra, amount, priceLimit } = order;
    return extra?.totalQuoteAmount
        ? extra.totalQuoteAmount
        : new BigNumber(amount ?? 0).multipliedBy(priceLimit ?? 0).toString(10);
};
export const getOrdersToDisplay = (orders, ordersPerPage) => orders.length > ordersPerPage ? orders.slice(0, ordersPerPage) : orders;
export const hasVaultProxyDepositTarget = (order) => order.extra?.depositTargetId && order.extra?.transferDepositTargetId;
