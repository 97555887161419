import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { OwnerVerificationStatusRow } from '@copper/components/AddressBook/Crypto/OwnerVerification';
import s from '@copper/components/AddressBook/Forms/Verification/OwnerInformation/OwnerInfoForm.module.css';
import { checkIsOwnerVerified } from '@copper/components/AddressBook/Owners/helpers';
import { getOwnerAddress, getOwnerIcon } from '@copper/components/AddressBook/helpers';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { Select } from '@copper/ui-kit';
import { useSelector } from 'react-redux';
export const OwnerSelect = ({ onChange, value, address, placeholder = 'Search address owners', view }) => {
    const { organizationId } = useSelector(selectCurrentOrganization);
    const addressOwners = address._embedded?.owners || [];
    return (_jsx(Select, { placeholder: placeholder, items: addressOwners, searchFields: ['legalName'], getKey: (owner) => owner.ownerId, getTopRow: (owner) => (_jsxs("div", { className: s.ownerName, children: [owner.legalName, getOwnerIcon({ owner, organizationId: address.organizationId || organizationId }), !checkIsOwnerVerified(owner) && (_jsx(OwnerVerificationStatusRow, { size: "s", status: owner.verificationStatus }))] })), getBottomRow: (owner) => _jsxs("div", { className: s.ownerAddress, children: [getOwnerAddress(owner), " "] }), selected: value, onChange: onChange, reversedStyles: true, emptyDataString: 'No owners yet.', emptySearchString: 'No owners found.', view: view }));
};
