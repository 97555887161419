import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cuid from 'cuid';
import { selectAccountId } from '@copper/entities/accounts/accounts-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { createPortfolio, fetchPortfolios } from '@copper/entities/portfolios/portfolios-reducer';
import { selectGroupedPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { Button, FormControl, FormLabel, Input, PopupContent, PopupFooter, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
export const CSLPledgeFormForm = ({ onSuccess }) => {
    const { openSnackbar } = useSnackBar();
    const dispatch = useDispatch();
    const accountId = useSelector(selectAccountId);
    const portfolios = useSelector(selectGroupedPortfolios);
    const { organizationId } = useSelector(selectCurrentOrganization);
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();
    const parentPortfolio = portfolios.vaultPortfolios[0];
    const onSubmit = handleSubmit(async (values) => {
        try {
            const data = {
                accountId,
                organizationId,
                portfolioId: cuid(),
                portfolioName: values.name?.trim() ?? '',
                portfolioType: 'csl-pledge',
                parentPortfolioId: parentPortfolio?.portfolioId
            };
            await dispatch(createPortfolio(data)).unwrap();
            await dispatch(fetchPortfolios({ organizationId }));
            onSuccess();
            openSnackbar({ type: 'success', message: 'Your account has been added' });
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    });
    return (_jsxs("form", { onSubmit: onSubmit, children: [_jsx(PopupContent, { children: _jsxs(FormControl, { errorMessage: errors.name?.message, children: [_jsx(FormLabel, { children: "Account name" }), _jsx(Input, { size: "l", ...register('name'), maxLength: 128 })] }) }), _jsx(PopupFooter, { rightSection: _jsx(Button, { type: "submit", text: "Create", loading: isSubmitting }) })] }));
};
