import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import { IconClock, IconCrossOutline, IconTickOutline } from '@copper/ui-kit';
import s from './OwnerVerification.module.css';
export const OwnerVerificationStatusRow = ({ status, size = 'm', view = 'default' }) => {
    if (!status || status === 'not-required') {
        return null;
    }
    const iconSize = size === 's' ? 16 : 24;
    const getStatus = () => {
        switch (status) {
            case 'verified':
                return 'Verified';
            case 'processing':
                return 'Processing';
            case 'rejected':
                return 'Rejected';
            default:
                return 'Not verified';
        }
    };
    const getIcon = () => {
        if (status === 'verified')
            return _jsx(IconTickOutline, { width: iconSize, height: iconSize });
        if (status === 'processing')
            return _jsx(IconClock, { width: iconSize, height: iconSize });
        return _jsx(IconCrossOutline, { width: iconSize, height: iconSize });
    };
    return (_jsxs("div", { className: cn(s.container, s[`view_${view}`], {
            [s.container_unverified]: status !== 'verified',
            [s.container_pending]: status === 'processing' || status === 'new'
        }), children: [getIcon(), _jsx("p", { className: s[`size_${size}`], children: getStatus() })] }));
};
