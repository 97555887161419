import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Clearloop } from '@copper/client/components/Clearloop';
import { Deposit } from '@copper/client/components/Deposit';
import { Generate } from '@copper/client/components/Generate';
import { Lend } from '@copper/client/components/Lend';
import { OxygenBorrowDCT } from '@copper/client/components/OxygenBorrowDCT';
import { OxygenDelegation } from '@copper/client/components/OxygenDelegation';
import { OxygenDeposit } from '@copper/client/components/OxygenDeposit';
import { OxygenWithdraw } from '@copper/client/components/OxygenWithdraw';
import { Settle } from '@copper/client/components/Settle';
import { Stake } from '@copper/client/components/Stake';
import { Transfer } from '@copper/client/components/Transfer';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
export const ToolbarPanelContent = () => {
    const { activeTool } = useToolbar();
    return (_jsxs(_Fragment, { children: [activeTool === 'transfer' && _jsx(Transfer, {}), activeTool === 'oxygen-withdraw' && _jsx(OxygenWithdraw, {}), activeTool === 'oxygen-deposit' && _jsx(OxygenDeposit, {}), activeTool === 'oxygen-borrow-dct' && _jsx(OxygenBorrowDCT, {}), activeTool === 'oxygen-delegation' && _jsx(OxygenDelegation, {}), activeTool === 'clearloop' && _jsx(Clearloop, {}), activeTool === 'stake' && _jsx(Stake, {}), activeTool === 'settle' && _jsx(Settle, {}), activeTool === 'deposit' && _jsx(Deposit, {}), activeTool === 'generate' && _jsx(Generate, {}), activeTool === 'lend' && _jsx(Lend, {})] }));
};
