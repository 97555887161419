import { sendTrackingEvent } from '@copper/utils';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
export const usePathNameAnalytics = () => {
    const params = useParams();
    useEffect(() => {
        const keysToReplace = ['userId', 'portfolioId', 'organizationId'];
        let pageLocation = location.href;
        if (keysToReplace.some((key) => key in params)) {
            pageLocation = keysToReplace.reduce((acc, key) => (key in params ? acc.replace(params[key] ?? '', `{${key}}`) : acc), pageLocation);
        }
        sendTrackingEvent('page_view', { page_location: pageLocation });
    }, [location.pathname]);
};
