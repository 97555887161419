import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router';
import { FormResultFrame } from '@copper/components';
import { isLoanWithCollateralType } from '@copper/components/Loans/helpers';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { Alert, ButtonLink } from '@copper/ui-kit';
import { formatCurrency } from '@copper/utils';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import s from './Result.module.css';
export const Result = ({ onDone, onRepeat, loanId, error }) => {
    const navigate = useNavigate();
    const { getValues } = useFormContext();
    const { getDecimals } = useCurrenciesInfo();
    const { amount, currency, collateralAmount, collateralCurrency, loanType } = getValues();
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: s.frameContainer, children: _jsxs(FormResultFrame, { status: error ? 'failure' : 'success', onDone: onDone, children: [_jsx("p", { className: cn(s.title, s[error ? 'alert' : '']), children: error ? 'Loan creation failed' : 'Your loan has been booked' }), _jsxs("p", { className: s.loanAmount, children: [formatCurrency(amount || '0', {
                                    decimalPlaces: getDecimals(currency.currency, { useDictionaryDecimal: true })
                                }), ' ', currency.currency] }), isLoanWithCollateralType(loanType) && (_jsxs("p", { className: s.collateralAmount, children: ["Collateral", ' ', formatCurrency(collateralAmount || '0', {
                                    decimalPlaces: getDecimals(collateralCurrency?.currency, {
                                        useDictionaryDecimal: true
                                    })
                                }), ' ', collateralCurrency?.currency] })), error && (_jsx("div", { className: s.errorWrapper, children: _jsx(Alert, { type: "error", message: error, noBottomMargin: true }) }))] }) }), !error && (_jsx("div", { className: s.item, children: _jsx("div", { className: s.button, children: _jsx(ButtonLink, { text: "View loan details", onClick: () => navigate(`/accounts/all/lending?loanId=${loanId}`) }) }) })), _jsx("div", { className: s.item, children: _jsx("div", { className: s.button, children: _jsx(ButtonLink, { text: "Repeat loan", onClick: onRepeat }) }) })] }));
};
