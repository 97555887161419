import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectStakingCurrencies } from '@copper/entities/stake/stake-selector';
import { SearchField } from '@copper/ui-kit';
import { StakeCard } from '../StakeCard';
import { filterStakingCurrencies, sortStakingCurrencies } from './helpers';
import s from './Initial.module.css';
export const Initial = ({ onNext }) => {
    const stakingCurrencies = useSelector(selectStakingCurrencies);
    const [searchQuery, setSearchQuery] = useState('');
    const sortedCurrencies = useMemo(() => sortStakingCurrencies(stakingCurrencies), [stakingCurrencies]);
    const filteredCurrencies = filterStakingCurrencies(sortedCurrencies, searchQuery);
    return (_jsxs("div", { className: s.container, children: [_jsx("h2", { className: s.title, children: "Select asset" }), _jsx("div", { className: s.search, children: _jsx(SearchField, { view: "tool", size: "l", value: searchQuery, placeholder: "Search", onChange: (value) => setSearchQuery(value) }) }), _jsxs("div", { className: s.count, children: [filteredCurrencies.length, " assets"] }), filteredCurrencies.length > 0 ? (filteredCurrencies.map((item) => (_jsx(StakeCard, { currency: item.currency, onClick: () => onNext(item) }, item.mainCurrency)))) : (_jsx("div", { className: s.empty, children: "No asset with this name found" }))] }));
};
