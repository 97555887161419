import { groupBy } from 'lodash-es';
import { getPermissionGroupsFromSet } from '@copper/utils';
export const normalizeUser = (user) => {
    const { _embedded = {}, ...rootData } = user;
    const { identities = [], userProfile = {}, userApps = [] } = _embedded;
    return {
        ...rootData,
        ...userProfile,
        userApps,
        initials: `${rootData?.firstName?.[0] ?? ''}${rootData?.lastName?.[0] ?? ''}`,
        identities: normalizeUserIdentities(identities),
        isAdmin: rootData.userType === 'admin',
        isDeleted: !!rootData.deletedAt
    };
};
export const normalizeCurrentUser = (user) => {
    const { _embedded = {} } = user;
    return {
        ...normalizeUser(user)
    };
};
export const normalizeAdminUser = (user) => {
    const { _embedded = {} } = user;
    const { isActivated = true, permissionGroups, permissionSets = [] } = _embedded;
    return {
        ...normalizeUser(user),
        permissionGroups: permissionGroups
            ? permissionGroups
            : getPermissionGroupsFromSet(permissionSets),
        permissionSets,
        isActivated: isActivated
    };
};
export const normalizeUserIdentities = (identities) => groupBy(identities, 'type');
