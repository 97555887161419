import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Enter an account name.'),
    shardType: Yup.string().when('appType', {
        is: (value) => value === 'legacy',
        then: (schema) => schema.required('Select shard type.')
    })
});
export const getPopupVaultTitle = ({ portfolioType = 'trading-vault', isDefiVault = false }) => {
    let portfolioTypeName = 'vault';
    if (portfolioType === 'trading-vault') {
        portfolioTypeName = 'trading vault';
    }
    else if (portfolioType === 'custody' && isDefiVault) {
        portfolioTypeName = 'DeFi vault';
    }
    else if (portfolioType === 'fees-vault') {
        portfolioTypeName = 'fee vault';
    }
    return `Create ${portfolioTypeName} account`;
};
