import { isClearloopPortfolio } from '@copper/helpers/portfolios';
import { checkRequiredNetwork, validateAmountField } from '@copper/helpers/validation';
import cuid from 'cuid';
import { format } from 'date-fns';
import * as Yup from 'yup';
export const DEFAULT_FEE_LEVEL = 'medium';
export const validationSchema = Yup.object()
    .shape({
    currency: Yup.object().shape({ currency: Yup.string().required('Select an asset.') }),
    mainCurrency: Yup.string().when(['origin', 'destination', 'currency'], {
        is: (origin, destination) => checkRequiredNetwork({ origin, destination }),
        then: (schema) => schema.required('Select a network.')
    }),
    origin: Yup.object().shape({
        portfolioType: Yup.string().required('Select a from account.')
    }),
    destination: Yup.object()
        .required('Select a to account.')
        .test('withdrawTarget', 'You cannot transfer this asset to the account you selected.', (value, { parent = {} }) => {
        if (!parent?.withdrawTargets) {
            return true;
        }
        if (value?.portfolioId && !isClearloopPortfolio(value?.portfolioType)) {
            return parent?.withdrawTargets?.some((item) => item?.portfolioId === value?.portfolioId);
        }
        return true;
    })
        .test('cryptoAddress', 'You cannot transfer this asset to the account you selected.', (value, { parent = {} }) => {
        if (!value?.cryptoAddressId) {
            return true;
        }
        if (value?.currency === parent?.currency?.currency) {
            return true;
        }
        if (value?.acceptTokens) {
            return parent?.currency?.mainCurrencies?.includes(value?.mainCurrency ?? '');
        }
    }),
    amount: validateAmountField(),
    toAddress: Yup.string().test('toAddress', 'Select an external address.', (value, { parent = {} }) => {
        if (!isNewExternalAddress(parent?.destination))
            return true;
        return !!value;
    }),
    conditionalTransfers: Yup.array().of(Yup.object()
        .shape({
        portfolioType: Yup.string(),
        cryptoAddressId: Yup.string()
    })
        .test('portfolioType or cryptoAddressId', 'Select an account or crypto address', (value) => 'portfolioType' in value || 'cryptoAddressId' in value)),
    description: Yup.string().max(500, 'No more than 500 characters.'),
    dryRunError: Yup.string().test('dryRunError', '', (value) => !value),
    toCryptoAddressOwner: Yup.object().test('toCryptoAddressOwner', 'Select a recipient.', (value, { parent = {} }) => {
        const { destination } = parent;
        if (destination && 'cryptoAddressId' in destination && destination.isMultiOwner) {
            return !!value;
        }
        return true;
    })
})
    .required();
export const formatFeeEstimatedTime = (value) => {
    const [hours = 0, minutes = 0, seconds = 0] = format(Number(value), 'H:m:s').split(':');
    return Boolean(Number(value))
        ? `≈${Boolean(Number(hours)) ? ` ${hours}h` : ''}${Boolean(Number(minutes)) ? ` ${minutes}m` : ''}${Boolean(Number(seconds)) ? ` ${seconds}s` : ''}`
        : value;
};
export const normalizeDryRunResult = (responseOrders) => responseOrders.reduce((acc, order) => {
    const orderId = cuid();
    return {
        ...acc,
        [orderId]: {
            orderId,
            ...order,
            ...order.extra
        }
    };
}, {});
export const isNewExternalAddress = (destination) => destination?.cryptoAddressId === '';
