import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getClearloopExchanges } from '@copper/api/clearloop';
import * as dictionariesApi from '@copper/api/dictionaries';
import { reportingCurrency } from '@copper/constants/currency';
import { normalizeCountries } from '@copper/entities/countries/countries-normalize';
import { normalizeCurrencies } from '@copper/entities/currencies/currencies-normalize';
import { setCurrencyRates } from '@copper/entities/currencyRates/currencyRates-reducer';
import { normalizeClearloopExchanges, normalizeExchanges } from '@copper/entities/exchanges/exchanges-normalize';
import { normalizeNetworks } from '@copper/entities/networks/networks-normalize';
const initialState = {
    countries: {
        entities: {}
    },
    currencies: {
        entities: {},
        tokens: {}
    },
    networks: {},
    exchanges: {
        entities: {}
    },
    clearloopExchanges: {
        entities: {}
    },
    clearLoopTokens: [],
    feeTypes: []
};
export const fetchDictionaries = createAsyncThunk('dictionaries/get', async (_, thunkAPI) => {
    try {
        const response = await dictionariesApi.get(reportingCurrency);
        if (response.data.currencies) {
            thunkAPI.dispatch(setCurrencyRates(response.data.currencies));
        }
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchNetworks = createAsyncThunk('dictionaries/getNetworks', async (_, thunkAPI) => {
    try {
        const response = await dictionariesApi.getNetworks();
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchClearloopExchanges = createAsyncThunk('clearloopExchanges/get', async (organizationId, thunkAPI) => {
    try {
        const response = await getClearloopExchanges(organizationId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchFeeTypes = createAsyncThunk('dictionaries/getFeeTypes', async (_, thunkAPI) => {
    try {
        const response = await dictionariesApi.getFeeTypes();
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const dictionariesSlice = createSlice({
    name: 'dictionaries',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchDictionaries.fulfilled, (state, { payload }) => {
            state.exchanges = normalizeExchanges(payload.exchanges);
            state.countries = normalizeCountries(payload.countries ?? []);
            state.currencies = normalizeCurrencies(payload.currencies ?? []);
            if (payload.clearLoopTokens) {
                state.clearLoopTokens = payload.clearLoopTokens;
            }
        });
        addCase(fetchClearloopExchanges.fulfilled, (state, { payload }) => {
            state.clearloopExchanges = normalizeClearloopExchanges(payload.clearLoopExchanges);
        });
        addCase(fetchFeeTypes.fulfilled, (state, { payload }) => {
            state.feeTypes = payload?.feeTypes ?? [];
        });
        addCase(fetchNetworks.fulfilled, (state, { payload }) => {
            state.networks = normalizeNetworks(payload.networks);
        });
    }
});
export const dictionaries = dictionariesSlice.reducer;
