import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import useStateMachine from '@cassiozen/usestatemachine';
import { SelectLoanForm } from '@copper/client/components/Lend/SelectLoanForm';
import { LOAN_FORM_TITLES } from '@copper/components/Loans/constants';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { useRoutingContext } from '@copper/hooks/useRoutingContext';
import { AgencyLendingChildLoan } from './AgencyLendingChildLoan';
import { AgencyLendingParentLoan } from './AgencyLendingParentLoan';
import { BilateralLoan } from './BilateralLoan';
export const Lend = () => {
    const { setTopPanel } = useToolbar();
    const { route } = useRoutingContext();
    const [state, send] = useStateMachine({
        initial: 'initial',
        states: {
            initial: {
                on: {
                    BILATERAL: 'bilateral',
                    AGENCY: 'agency',
                    LENDER: 'lender'
                }
            },
            bilateral: {
                on: {
                    BACK: 'initial'
                }
            },
            agency: {
                on: {
                    BACK: 'initial'
                }
            },
            lender: {
                on: {
                    BACK: 'initial'
                }
            }
        }
    });
    useEffect(() => {
        if (route?.name === 'lend') {
            route?.state === 'bilateral' && send('BILATERAL');
            route?.state === 'agency' && send('AGENCY');
            route?.state === 'lender' && send('LENDER');
        }
    }, [route]);
    const handleOnBackClick = () => send('BACK');
    useEffect(() => {
        setTopPanel({
            title: LOAN_FORM_TITLES[state.value] || 'Lend',
            onBack: state.value !== 'initial' ? () => send('BACK') : undefined
        });
    }, [state, setTopPanel]);
    return (_jsxs("div", { children: [state.value === 'initial' && _jsx(SelectLoanForm, { send: send }), state.value === 'bilateral' && _jsx(BilateralLoan, { onBack: handleOnBackClick }), state.value === 'lender' && _jsx(AgencyLendingParentLoan, { onBack: handleOnBackClick }), state.value === 'agency' && _jsx(AgencyLendingChildLoan, { onBack: handleOnBackClick })] }));
};
