import { BigNumber } from 'bignumber.js';
import { oxygenMainNetwork } from '@copper/constants/currency';
import { sortBy } from 'lodash-es';
const FIAT_DECIMAL = 2;
const CRYPTO_DECIMAL = 8;
export const CRYPTO_SHORT_DECIMAL = 4;
export const getCurrencyName = (currencyId, currenciesEntities) => currenciesEntities[currencyId]?.name || '';
export const getCurrencyProtocol = (currencyId, mainCurrencyId, tokens) => tokens[`${currencyId}-${mainCurrencyId}`]?.protocol;
export const isCurrencyFiat = (currenciesEntities, currencyId) => {
    if (!currencyId) {
        return false;
    }
    return currenciesEntities[currencyId]?.fiat ?? false;
};
export const isOxygenFiat = (currencyId, tokens) => {
    if (!currencyId) {
        return false;
    }
    return tokens[`${currencyId}-${oxygenMainNetwork}`]?.tags?.includes('oxygen-fiat') ?? false;
};
export const isTokenizedAsset = (currencyId, tokens) => {
    if (!currencyId) {
        return false;
    }
    return (tokens[`${currencyId}-${oxygenMainNetwork}`]?.tags?.includes('oxygen-tokenized-asset') ?? false);
};
export const getExplorerUrl = (networks, mainCurrencyId) => {
    if (!mainCurrencyId) {
        return '';
    }
    return networks[mainCurrencyId]?.explorerUrl ?? '';
};
export const getAddressExplorerUrl = (networks, mainCurrencyId) => {
    if (!mainCurrencyId) {
        return '';
    }
    return networks[mainCurrencyId]?.addressExplorerUrl ?? '';
};
export const hasAdditionalFields = (name, tokens, currencyId, mainCurrencyId) => {
    if (!currencyId || !mainCurrencyId) {
        return false;
    }
    const additionalFields = tokens[`${currencyId}-${mainCurrencyId}`]?.additionalFields ?? [];
    return additionalFields.includes(name);
};
export const getNetworkFeesParams = (tokens, mainCurrencyId) => {
    if (!mainCurrencyId) {
        return [];
    }
    return tokens[`${mainCurrencyId}-${mainCurrencyId}`]?.extra?.networkFeesParameters ?? [];
};
export const geCurrencyFeesLevels = (tokens, currencyId, mainCurrencyId) => {
    if (!currencyId || !mainCurrencyId) {
        return [];
    }
    return tokens[`${currencyId}-${mainCurrencyId}`]?.feesLevels ?? [];
};
export const getPriorityMainCurrency = (tokens, currencyId, mainCurrencyId) => tokens[`${currencyId}-${mainCurrencyId}`]?.extra?.priorityForMainCurrency ?? false;
export const getCurrencyFeeByLevel = (tokens, feeLevel, currencyId, mainCurrencyId) => {
    const feesLevels = geCurrencyFeesLevels(tokens, currencyId, mainCurrencyId);
    const selectedLevel = feesLevels.find((fees) => fees.feeLevel === feeLevel);
    return selectedLevel ? (selectedLevel.value ?? '0') : '0';
};
export const getFeesCurrencies = (tokens, currencyId, mainCurrencyId) => tokens[`${currencyId}-${mainCurrencyId}`]?.extra?.feesCurrencies ?? [];
export const getCurrencyDecimal = ({ tokens, currencyId, mainCurrencyId, useDictionaryDecimal = false }) => {
    const currencyData = tokens[`${currencyId}-${mainCurrencyId ?? currencyId}`];
    if (!currencyId || !currencyData) {
        return CRYPTO_DECIMAL;
    }
    if (currencyData.fiat) {
        return useDictionaryDecimal ? Number(currencyData?.decimal ?? FIAT_DECIMAL) : FIAT_DECIMAL;
    }
    const mainCurrencyDecimal = Number(currencyData?.decimal);
    if (useDictionaryDecimal) {
        return mainCurrencyDecimal ? Number(mainCurrencyDecimal) : CRYPTO_DECIMAL;
    }
    return Math.min(CRYPTO_DECIMAL, mainCurrencyDecimal || CRYPTO_DECIMAL);
};
export const getMainCurrencies = (currenciesEntities, currency) => currenciesEntities[currency ?? '']?.mainCurrencies ?? [];
export const sortCurrencies = (currencies) => currencies.sort((a, b) => {
    if ((a.priority ?? 0) > (b.priority ?? 0))
        return -1;
    if ((a.priority ?? 0) < (b.priority ?? 0))
        return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase())
        return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase())
        return 1;
    return 0;
});
export const calculateReportingValue = (value, rateReporting, decimalPlaces = 2) => new BigNumber(value).multipliedBy(rateReporting).toFixed(decimalPlaces);
export const filterCurrenciesByTags = (currencies, tokens, containsTags = []) => currencies.filter((currency) => {
    const tags = currency.mainCurrencies.reduce((acc, mainCurrencyId) => {
        const tags = tokens[`${currency.currency}-${mainCurrencyId}`]?.tags ?? [];
        return [...acc, ...tags];
    }, []);
    return containsTags.some((el) => tags.includes(el));
});
export const filterCurrenciesByExchange = (currencies, portfolio, exchangesEntities) => {
    const exchangeName = portfolio?.extra?.exchange;
    const exchangeAccountType = portfolio?.extra?.exchangeAccountType;
    const exchangeAccounts = exchangesEntities[exchangeName ?? '']?.exchangeAccounts?.find((exchangeAccount) => exchangeAccount.accountType === exchangeAccountType);
    return currencies.filter((currency) => exchangeAccounts?.currencies.some((exchangeCurrency) => exchangeCurrency.currency === currency.currency));
};
export const isMultiNetworkCurrencyOrToken = (currency, mainCurrency, mainCurrencies) => mainCurrency && (currency !== mainCurrency || (mainCurrencies ?? [])?.length > 1);
export const computeExplorerLink = ({ hashId, explorerUrl, placeholder = '{hash}' }) => {
    if (!explorerUrl || !hashId) {
        return '';
    }
    return explorerUrl.includes(placeholder)
        ? `${explorerUrl.replace(placeholder, hashId)}`
        : `${explorerUrl}${hashId}`;
};
export const isSmartContractCurrency = ({ currency, mainCurrency, tokens }) => {
    const nativeCurrency = tokens[`${currency}-${mainCurrency}`]?.extra?.nativeCurrency;
    return (tokens[`${nativeCurrency}-${mainCurrency}`]?.tags ?? []).includes('smart-contracts-supported');
};
export const getAccessibleMainCurrencies = (currenciesEntities, tokens, currency) => {
    const mainCurrencies = currenciesEntities[currency ?? '']?.mainCurrencies?.filter((mainCurrency) => Boolean(tokens[`${currency}-${mainCurrency}`]?.tags?.length)) ?? [];
    return sortBy(mainCurrencies, (mainCurrency) => getPriorityMainCurrency(tokens, currency, mainCurrency) ? 0 : 1);
};
export const getAllAccessibleMainCurrencies = (currenciesEntities, tokens) => Object.keys(currenciesEntities).reduce((acc, currencyItem) => {
    const mainCurrencies = currenciesEntities[currencyItem]?.mainCurrencies?.filter((mainCurrency) => Boolean(tokens[`${currencyItem}-${mainCurrency}`]?.tags?.length)) ?? [];
    return [...new Set([...acc, ...mainCurrencies])];
}, []);
export const currencyHasRBF = (currencyId, mainCurrency, tokens) => {
    if (!currencyId || !mainCurrency) {
        return false;
    }
    return tokens[`${currencyId}-${mainCurrency}`]?.tags?.includes('rbf') ?? false;
};
// For some currencies we should use blockNumber in explorer links in all UI, for example: ICP L2 (CKBTC, CKETH)
export const useBlockHeightInExplorerLink = (mainCurrency, tokens) => tokens[`${mainCurrency}-${mainCurrency}`]?.tags?.includes('use-block-in-explorer-link');
