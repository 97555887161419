import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Amount, AssetIcon } from '@copper/components';
import { getAvailableBalance } from '@copper/components/Forms/Amount/helpers';
import { PortfolioSelect } from '@copper/components/Forms/Portfolio';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { selectActiveOxygenCurrencies } from '@copper/entities/currencies/currencies-selector';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
import { useFetchWallets } from '@copper/hooks/useFetchWallets';
import { Alert, ChoiceGroup, FormControl, FormLabel, Select } from '@copper/ui-kit';
import { ParentPortfolio } from '../ParentPortfolio';
import s from './Form.module.css';
const formId = 'delegation-form';
export const DelegationForm = ({ onSubmit, form }) => {
    const { control, formState: { errors, isSubmitting }, handleSubmit, setValue, watch } = form;
    const { fetchWalletsForCurrency } = useFetchWallets();
    const portfolios = useSelector(selectPortfolios);
    const currencies = useSelector(selectActiveOxygenCurrencies);
    const { entities: currenciesEntities } = useSelector(selectCurrencies);
    const walletsByPortfolioId = useSelector(selectWalletsByPortfolioId);
    const { currency, account, type } = watch();
    const parentPortfolio = portfolios[account?.extra?.parentPortfolioId ?? ''];
    const availableBalance = useMemo(() => getAvailableBalance({
        walletsByPortfolioId,
        currenciesEntities,
        portfolio: type === 'delegate' ? parentPortfolio : account,
        currencyId: currency?.currency
    }), [currency, account, walletsByPortfolioId, currenciesEntities, parentPortfolio]);
    useEffect(() => {
        setValue('availableBalance', availableBalance);
    }, [availableBalance, setValue]);
    useEffect(() => {
        if (!currency?.currency) {
            return;
        }
        fetchWalletsForCurrency(currency);
    }, [currency]);
    return (_jsxs(_Fragment, { children: [_jsx(FormProvider, { ...form, children: _jsxs("form", { id: formId, className: s.formContainer, onSubmit: handleSubmit(onSubmit), children: [_jsx(FormControl, { children: _jsx(Controller, { name: "type", control: control, render: ({ field }) => (_jsx(ChoiceGroup, { items: [
                                        { label: 'Pledge', value: 'delegate' },
                                        { label: 'Unpledge', value: 'undelegate' }
                                    ], name: field.name, value: field.value, view: "tool", size: "s", getItemLabel: (item) => item.label, getItemKey: (item) => item.value, onChange: ({ newValue }) => field.onChange(newValue.value) })) }) }), type === 'delegate' && (_jsx(Alert, { type: "success", message: "Pledged assets are securely stored within the Copper ecosystem." })), _jsxs(FormControl, { errorMessage: errors?.currency?.currency?.message, children: [_jsx(FormLabel, { view: "tool", children: "Asset" }), _jsx(Controller, { control: control, name: "currency", render: ({ field }) => (_jsx(Select, { view: "tool", placeholder: "Select asset", searchPlaceholder: "Search assets", items: currencies, searchFields: ['name', 'currency'], getKey: (asset) => asset?.currency, getIcon: (asset) => _jsx(AssetIcon, { currency: asset?.currency, size: "s" }), getTopRow: (asset) => asset?.name, getBottomRow: (asset) => asset?.currency, emptySearchString: "No assets match this name", onChange: field.onChange, selected: field.value, reversedStyles: true })) })] }), _jsx("div", { className: s.portfolioSelectWrapper, children: _jsx(PortfolioSelect, { view: "tool", name: "account", label: type === 'delegate' ? 'To' : 'From', types: ['csl-pledge'], control: control, currencyId: currency?.currency, errorMessage: errors?.account?.portfolioId?.message, hasUnavailableToggle: type === 'undelegate', children: _jsx(ParentPortfolio, { label: type === 'delegate' ? 'From' : 'To', portfolio: account, currency: currency }) }) }), _jsx(Amount, { view: "tool", name: "amount", control: control, currency: currency?.currency, rightSection: currency?.currency, errorMessage: errors?.amount?.message, portfolioId: type === 'delegate' ? parentPortfolio?.portfolioId : account?.portfolioId })] }) }), _jsx(SwitchStateRow, { type: "submit", form: formId, loading: isSubmitting })] }));
};
