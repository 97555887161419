import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from '@copper/ui-kit';
import s from '../Assets.module.css';
export const ETH = {
    title: 'ETH',
    id: 'eth',
    qa: [
        {
            question: 'How do I stake ETH?',
            answer: (_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, select Stake in the side toolbar and then select Ethereum (ETH)." }), _jsx("li", { children: "Select an account to stake from." }), _jsxs("li", { children: [_jsx("p", { className: s.spaceAfter, children: "Choose a stake amount in exact increments of 32 ETH." }), _jsx("p", { className: s.spaceAfter, children: "If you're staking with a Figment validator, you can stake between 32 and 8,000 ETH. With a Copper validator, you can stake from 32 to 3,200 ETH." })] }), _jsx("li", { children: "Choose a validator to stake with from 2 options: Copper or Figment. Figment is a non-custodial institutional staking provider." }), _jsx("li", { children: "Review and sign using Copper Unlimited." }), _jsx("li", { children: "Wait out the bonding period." }), _jsx(Alert, { type: "info", message: "This can take anywhere between 2 hours to 3 weeks as ETH uses a queue-based system. You will not earn rewards during this period." })] }))
        },
        {
            question: 'How do I unstake ETH?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "You can unstake, or withdraw your assets, once your stake has been active for at least 256 epochs (approximately 27 hours). To do so, follow these steps:" }), _jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "From the dashboard, navigate to your staking vault, where you can view a list of all the ETH validators you are staking to." }), _jsx("li", { children: "Select the validator you would like to unstake from, and then select Unstake." }), _jsx("li", { children: "If prompted, sign using Copper Unlimited to confirm." }), _jsx("li", { children: "Wait out the unbonding period." })] }), _jsxs("p", { children: ["Unbonding times on ETH are variable and depend on the total number of validators unbonding on the Ethereum network at any given time. For full information on the ETH unbonding process, please refer to this documentation:", ' ', _jsx("a", { target: "_blank", href: "https://ethereum.org/en/staking/withdrawals/", rel: "noreferrer", children: "Staking withdrawals | ethereum.org." })] }), _jsx("p", { children: "At the end of the unbonding period, unstaked assets will be made available in your staking vault." })] }))
        },
        {
            question: 'Is there a minimum or maximum staking amount for ETH?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "Yes. If you are staking with a Figment validator, you can stake between 32 and 8,000 ETH in a single transaction." }), _jsx("p", { className: s.spaceAfter, children: "If you are staking with a Copper validator, you can stake between 32 and 3,200 ETH in a single transaction." }), _jsx("p", { children: "Please note: the Ethereum network requires staking in exact increments of 32 ETH." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Minimum stake" }) }), _jsx("td", { className: s.td, children: "32 ETH" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake (Copper validator)" }) }), _jsx("td", { className: s.td, children: "3,200 ETH" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Maximum stake (Figment validator)" }) }), _jsx("td", { className: s.td, children: "8,000 ETH" })] })] }) }), _jsx("p", { children: "The maximum stake indicated is for individual transactions. You may stake additional funds by initiating multiple transactions." })] }))
        },
        {
            question: 'How often will I earn ETH staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "Rewards for ETH are issued every few days. If you are staking with a Copper validator, you can check the reward frequency applicable at the time of staking." }), _jsx("p", { children: "Alternatively, you can view this information in your staking details by clicking on the transaction on your staking dashboard." }), _jsx("table", { className: s.table, children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Every few days" })] }) }) })] }))
        },
        {
            question: 'How can I claim my ETH staking rewards?',
            answer: _jsx("p", { children: "Your ETH staking rewards will be automatically deposited in your staking vault." })
        }
    ]
};
