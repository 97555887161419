import * as cryptoAddressesApi from '@copper/api/cryptoAddresses';
import * as travelRulesAPI from '@copper/api/travelRules';
export const getCryptoAddress = (cryptoAddressId) => cryptoAddressesApi.getAddress(cryptoAddressId).then(({ data }) => data);
export const fetchCryptoAddresses = (params) => cryptoAddressesApi.get(params).then(({ data }) => {
    return data.cryptoAddresses.map((address) => {
        return {
            ...address,
            _embedded: {
                owners: data._embedded?.owners?.filter((owner) => address.ownerIds?.includes(owner.ownerId)) ||
                    []
            }
        };
    });
});
export const getTravelRules = (exchangeName) => travelRulesAPI.getTravelRulesByName(exchangeName).then((res) => res.data);
export const checkTravelRules = (address) => travelRulesAPI.checkTravelRules(address).then((res) => res.data);
export const generateOwnerVerificationLink = (ownerId) => cryptoAddressesApi.generateOwnerVerificationLink(ownerId);
export const getAddressOwner = (params) => cryptoAddressesApi.getAddressOwner(params).then((response) => response.data);
export const getNonCustodialWallets = (params) => cryptoAddressesApi
    .getNonCustodialWallets(params)
    .then((response) => response.data.nonCustodialWallets);
export const fetchOwners = (params) => cryptoAddressesApi.getAddressOwners(params).then((res) => res.data.owners);
