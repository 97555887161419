import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { AssetIcon, InfoAccount, InfoRow } from '@copper/components';
import { useAddressBookContext } from '@copper/components/AddressBook/context';
import { getOwnerIcon } from '@copper/components/AddressBook/helpers';
import { CounterpartyNetworkCard } from '@copper/components/Counterparty/CounterpartyNetworkCard';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { WithdrawalPassword } from '@copper/components/WithdrawalPassword';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { calculateReportingValue, getCurrencyName } from '@copper/helpers';
import { isExternalPortfolio, isTradingPortfolio } from '@copper/helpers/portfolios';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { Atom } from '@copper/ui-kit';
import { formatCurrency, formatFiat, truncateStr } from '@copper/utils';
import BigNumber from 'bignumber.js';
import { Link } from 'react-router-dom';
import { formatFeeEstimatedTime } from '../../helpers';
import s from './Summary.module.css';
const Summary = ({ isSubmitting, data, onNext }) => {
    const { getDecimals } = useCurrenciesInfo();
    const { getBaseUrl } = useAddressBookContext();
    const currenciesRates = useSelector(selectRates);
    const { entities: currenciesEntities } = useSelector(selectCurrencies);
    const currentOrganization = useSelector(selectCurrentOrganization);
    const { amount, currency, mainCurrency, origin, destination, description, estimatedFees, withdrawFee, exchangeFees, includeFeeInWithdraw, feeCanBeIncluded, conditionalTransfers, toAddress, toCryptoAddressOwner } = data;
    const isWithdrawalPassword = origin?.portfolioType === 'external' && currentOrganization.extra?.encryptionPublicKey;
    const destinationPortfolioType = destination && 'portfolioType' in destination ? destination.portfolioType : undefined;
    const isInternalTransfer = isTradingPortfolio(origin?.portfolioType) && isTradingPortfolio(destinationPortfolioType);
    const estimatedFeesCurrency = estimatedFees?.feeCurrency
        ? estimatedFees?.feeCurrency
        : mainCurrency;
    const estimatedFeesRate = currenciesRates[estimatedFeesCurrency ?? ''];
    const feesRate = currenciesRates[currency?.currency ?? ''];
    if (!currency || !origin || !destination || !amount) {
        return null;
    }
    const currencyId = currency.currency;
    const feeAmount = estimatedFees?.fee ?? withdrawFee ?? exchangeFees?.withdrawalFee;
    const amountWithFees = !includeFeeInWithdraw && feeCanBeIncluded
        ? new BigNumber(amount).plus(feeAmount ?? 0).toFixed()
        : amount;
    const receivedAmount = (includeFeeInWithdraw && feeCanBeIncluded) || isExternalPortfolio(origin.portfolioType)
        ? new BigNumber(amount).minus(feeAmount ?? 0).toFixed()
        : amount;
    const confirmSummary = (password) => {
        onNext(password);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.containerSummary, children: [_jsx("h2", { className: s.title, children: "Summary" }), _jsx(InfoRow, { title: "Asset", titleColor: "ghost", children: _jsxs("div", { className: s.asset, children: [getCurrencyName(currencyId, currenciesEntities), _jsx("span", { className: s.ticker, children: currencyId }), _jsx(AssetIcon, { size: "s", currency: currencyId })] }) }), _jsx(InfoRow, { title: "From", titleColor: "ghost", children: _jsx(InfoAccount, { account: origin }) }), _jsxs(InfoRow, { title: "To", titleColor: "ghost", contentUnderTitle: 'counterpartyId' in destination, children: [('cryptoAddressId' in destination ||
                                        'portfolioId' in destination ||
                                        'nonCustodialWalletId' in destination) && (_jsx(InfoAccount, { account: destination, address: toAddress })), 'counterpartyId' in destination && (_jsx(CounterpartyNetworkCard, { counterparty: destination, view: "tool", form: "preview" })), 'bankAccountId' in destination && _jsx(InfoAccount, { account: destination })] }), toCryptoAddressOwner && (_jsx(InfoRow, { title: "Recipient", titleColor: "ghost", children: _jsx(Atom, { title: _jsx(Link, { to: `${getBaseUrl()}/owners/active?ownerId=${toCryptoAddressOwner.ownerId}`, children: toCryptoAddressOwner?.legalName }, toCryptoAddressOwner.ownerId), icon: getOwnerIcon({
                                        owner: toCryptoAddressOwner,
                                        organizationId: currentOrganization.organizationId
                                    }), align: "right" }) })), 'memo' in destination && (_jsx(InfoRow, { title: "Memo", children: truncateStr(destination?.memo, 10, 10) })), 'destinationTag' in destination && (_jsx(InfoRow, { title: "Memo", children: truncateStr(destination?.destinationTag, 10, 10) })), Boolean(conditionalTransfers?.length) &&
                                conditionalTransfers?.map((destination, index) => (_jsx(InfoRow, { title: "Then send to", titleColor: "ghost", children: _jsx(InfoAccount, { account: destination }) }, index))), estimatedFees?.estimatedTime && (_jsx(InfoRow, { title: "Estimated time", titleColor: "ghost", children: isInternalTransfer ? 'Instant' : formatFeeEstimatedTime(estimatedFees.estimatedTime) })), isInternalTransfer && (_jsx(InfoRow, { title: "Estimated time", titleColor: "ghost", children: "Instant" })), estimatedFees?.fee && (_jsxs(InfoRow, { title: "Estimated fee", titleColor: "ghost", children: [estimatedFees.fee, " ", estimatedFeesCurrency ?? '', ` (${formatFiat(calculateReportingValue(estimatedFees.fee, estimatedFeesRate ?? '0'))})`] })), withdrawFee && (_jsxs(InfoRow, { title: "Fee", titleColor: "ghost", children: [withdrawFee, " ", currencyId, ` (${formatFiat(calculateReportingValue(withdrawFee, feesRate ?? '0'))})`] })), exchangeFees?.withdrawalFee && (_jsxs(InfoRow, { title: "Fee", titleColor: "ghost", children: [exchangeFees.withdrawalFee, " ", currencyId, ` (${formatFiat(calculateReportingValue(exchangeFees.withdrawalFee, feesRate ?? '0'))})`] })), _jsx(InfoRow, { title: "You send", titleColor: "ghost", children: _jsxs("div", { className: s.youSend, children: [formatCurrency(amountWithFees, {
                                            decimalPlaces: getDecimals(currencyId, {
                                                mainCurrencyId: mainCurrency,
                                                useDictionaryDecimal: true
                                            })
                                        }), ' ', currencyId, feeCanBeIncluded && (_jsx("div", { className: s.includeFee, children: includeFeeInWithdraw ? 'Fee included' : 'Fee is not included' }))] }) }), description && (_jsx(InfoRow, { title: "Optional note", titleColor: "ghost", children: description })), _jsx(InfoRow, { titleColor: "ghost", title: includeFeeInWithdraw ? (_jsxs(_Fragment, { children: ["Recipient gets ", _jsx("br", {}), " approximately"] })) : ('Recipient gets '), children: _jsxs("span", { className: s.total, children: [formatCurrency(receivedAmount, {
                                            decimalPlaces: getDecimals(currencyId, {
                                                mainCurrencyId: mainCurrency,
                                                useDictionaryDecimal: true
                                            })
                                        }), ' ', currencyId] }) })] }), isWithdrawalPassword && (_jsx(WithdrawalPassword, { formId: "master-password", submitWithdrawalPassword: confirmSummary }))] }), isWithdrawalPassword && (_jsx(SwitchStateRow, { loading: isSubmitting, type: "submit", form: "master-password", text: "Confirm" })), !isWithdrawalPassword && (_jsx(SwitchStateRow, { loading: isSubmitting, text: "Confirm", onClick: () => onNext() }))] }));
};
export { Summary };
