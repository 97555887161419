import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as usersApi from '@copper/api/users';
import { normalizeAdminUsers } from '@copper/entities/adminUsers/adminUsers-normalize';
import { normalizeAdminUser } from '../user/user-normalize';
export const fetchAdminUsers = createAsyncThunk('adminUsers/getUsers', async (_, thunkAPI) => {
    try {
        const response = await usersApi.getUsers({
            admins: true,
            limit: 1000,
            includePermissions: true,
            includeUserProfile: true
        });
        return response.data.users;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createAdminUser = createAsyncThunk('adminUsers/createUser', async ({ data, twoFaCode }, thunkAPI) => {
    try {
        const response = await usersApi.createUser(data, twoFaCode);
        if (response.data.userId) {
            await thunkAPI.dispatch(fetchAdminUserDetails(response.data.userId));
        }
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteAdminUser = createAsyncThunk('adminUsers/deleteUser', async ({ userId, twoFaCode }, thunkAPI) => {
    try {
        await usersApi.deleteUser(userId, twoFaCode);
        return userId;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const reactivateUser = createAsyncThunk('adminUsers/reactivateUser', async ({ userId, twoFaCode }, thunkAPI) => {
    try {
        const response = await usersApi.reactivateUser(userId, twoFaCode);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateAdminUser = createAsyncThunk('adminUsers/updateUser', async (data, thunkAPI) => {
    try {
        const response = await usersApi.updateUser(data);
        await thunkAPI.dispatch(fetchAdminUserDetails(data.userId));
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchAdminUserDetails = createAsyncThunk('adminUsers/getUser', async (userId, thunkAPI) => {
    try {
        const { data } = await usersApi.getUser({
            userId,
            includeUserProfile: true,
            includePermissions: true
        });
        return data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
const adminUsersSlice = createSlice({
    name: 'adminUsers',
    initialState: {
        entities: {}
    },
    reducers: {},
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchAdminUsers.fulfilled, (state, { payload }) => {
            state.entities = normalizeAdminUsers(payload);
        });
        addCase(deleteAdminUser.fulfilled, (state, { payload }) => {
            state.entities[payload] = {
                ...state.entities[payload],
                isActivated: false,
                isDeleted: true
            };
        });
        addMatcher(isAnyOf(fetchAdminUserDetails.fulfilled, reactivateUser.fulfilled), (state, action) => {
            state.entities = {
                ...state.entities,
                [action.payload.userId]: normalizeAdminUser(action.payload)
            };
        });
    }
});
export const adminUsers = adminUsersSlice.reducer;
