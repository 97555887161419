import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { sendTrackingEvent } from '@copper/utils';
import { Topics } from './Topics';
import s from './Guide.module.css';
export const ClearloopGuide = () => {
    useEffect(() => {
        sendTrackingEvent('clearloop_guide_view');
    }, []);
    return (_jsx("div", { className: s.container, children: _jsx(Topics, {}) }));
};
